import React from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Layout from "../layout/layout"
import SEO from "../seo/seo"

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      blogTitle
      blogImage {
        fluid (maxWidth: 500) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        resize {
          src
          width
          height
        }
      }
      blogContent {
        childMarkdownRemark {
          html
        }
      }
      blogPostedAt (formatString: "MMMM Do, YYYY")
      createdAt (formatString: "MMMM Do, YYYY")
      blogAuthor {
        authorName
        authorTitle
        authorAvatar {
          fixed (height: 50, resizingBehavior: SCALE, quality: 100) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      } 
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 100,
    paddingBottom: 50
  },
  blogContentPage: {
    '& p': {
      '&:first-child': {
        '&:first-letter': {
          initialLetter: 2
        }
      }
    }
  },
  inlineBlock: {
    display: 'inline-block'
  }
}));

const BlogPostTemplate = (props) => {
  const classes = useStyles();

  const post = props.data.contentfulBlog
  const siteMetadata = props.data.site.siteMetadata

  const { previous, next } = props.pageContext

  return (
    <Layout>
      <SEO 
        title= {`${post.blogTitle} | ${siteMetadata.title}`}
        description= {siteMetadata.description} 
        image={post.blogImage.resize.src}
        // image={[post.blogImage.resize.src, post.blogImage.resize.width, post.blogImage.resize.height]}
      />
      <Container 
        wrap="wrap" 
        maxWidth="md" 
        className={classes.root}
      >
        <Grid 
          container 
          spacing={3}
        >
          <Grid 
            item 
            xs={12} 
          >
            <Box my={3}>
              <Typography 
                variant="h3" 
              >
                {post.blogTitle}
              </Typography>
            </Box>
            <Box 
              display="flex" 
              flexDirection="row" 
              my={3}
            >
              <Avatar 
                alt={post.blogAuthor.authorName}
              >
                <Img 
                  fixed={post.blogAuthor.authorAvatar.fixed} 
                  alt={post.blogAuthor.authorName} 
                />
              </Avatar>
              <Box 
                display="flex" 
                flexDirection="column" 
                ml={3}
              >
                <Box 
                  display="flex" 
                  flexDirection="row" 
                  alignItems="center"
                >
                  <Typography variant="body1">
                    {`${post.blogAuthor.authorName}, ${post.blogAuthor.authorTitle}`}
                  </Typography>
                </Box>
                <Box 
                  display="flex" 
                  flexDirection="row" 
                  alignItems="center"
                >
                  <Typography variant="body2">
                    {
                      !post.blogPostedAt 
                      ? post.createdAt
                      : post.blogPostedAt
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
            {
              post.blogImage && (
                <Box my={3}>
                  <Img fluid={post.blogImage.fluid} alt={post.blogImage.title} />
                </Box>
              )
            }
            {
              post.blogContent && (
                <Typography 
                  variant="body1" 
                  component="div"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.blogContent.childMarkdownRemark.html,
                    }}
                    className={classes.blogContentPage}
                  />
                </Typography>
              )
            }
          </Grid>            
          <Grid 
            item 
            container 
            space={5}
          >
            <Grid 
              item 
              xs={6}
            >
              {previous && (
                <Link to={"/blog/" + previous.slug} color="textPrimary">
                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                    <Typography variant="body1" className={classes.inlineBlock}>
                      ←
                    </Typography>
                    <Hidden smDown>
                      <Typography variant="subtitle1" component="p">
                        {previous.blogTitle}
                      </Typography>
                    </Hidden>
                  </Box>
                </Link>
              )}
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              {next && (
                <Link to={"/blog/" + next.slug} color="textPrimary">
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Hidden smDown>
                      <Typography variant="subtitle1" component="p" align="right">
                        {next.blogTitle}
                      </Typography>
                    </Hidden>
                    <Typography variant="body1" align="right" className={classes.inlineBlock}>
                      →
                    </Typography>
                  </Box>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate